export const QRTypes = {
  COMPS: "COMPS",
  comps: "comps",
  reduce: "reduce",
  REDUCE: "REDUCE",
  FULL: "full",
};

export const WALKUP_PROMOTOR_EMAIL = "walkup@mytallyapp.com";

export const changeQRColor = (type, userEmail = "") => {
  let fgColor;
  switch (type) {
    case QRTypes.COMPS:
    case QRTypes.comps:
      fgColor = "#006400";
      break;
    case QRTypes.REDUCE:
    case QRTypes.reduce:
      fgColor = "#DC3535";
      break;
    case QRTypes.FULL:
      fgColor = "#2F58CD";
      break;
    default:
      fgColor = "black";
      break;
  }
  return fgColor;
};

// export const changeQRColor = (type) => {
//   let fgColor;
//   switch (type) {
//     case "COMPS":
//     case "comps":
//       fgColor = "#006400";
//       break;
//     case "REDUCE":
//     case "reduce":
//       fgColor = "#DC3535";
//       break;
//     default:
//       fgColor = "black";
//   }
//   return fgColor;
// };
