import moment from "moment-timezone";

export const validateEmail = (email) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(email);
};

export const capitalizeNames = (fullName) => {
  const names = fullName.split(" ");
  const capitalizedNames = names.map(
    (name) => name.charAt(0).toUpperCase() + name.slice(1)
  );
  return capitalizedNames.join(" ");
};

export const checkConditionForSocialPopup = (payLoad) => {
  if (!payLoad.event.socialMedia) return false;

  const { facebookUrl, instagramUrl, tiktokUrl, youtubeUrl, whatsAppUrl } =
    payLoad.event.socialMedia;

  // Check if each URL is truthy (not null, undefined, or empty string)
  return [facebookUrl, instagramUrl, tiktokUrl, youtubeUrl, whatsAppUrl].some(
    (url) => Boolean(url)
  );
};

export function convertTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    // timeZone: "UTC",
  };
  return date.toLocaleString("en-US", options);
}

export function convertTimeWithTimezone(
  datetime,
  timezone = "America/New_York"
) {
  const convertedStartTimeInTimezone = moment(datetime)
    .tz(timezone ?? "America/New_York")
    .format("ddd, MMM D, YYYY [at] hh:mm A");

  return convertedStartTimeInTimezone;
}
