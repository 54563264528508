import { useState, useEffect, Fragment } from "react";
import { userInfoAPI } from "../../apis/user-info";

import { changeQRColor } from "../../utils/QR/get-QRcolor";
import { USER_ROLES } from "../../utils/user-roles/user-roles";
import { capitalizeNames, convertTimeWithTimezone } from "../../utils/helpers";
import { QRTypes } from "../../utils/QR/get-QRcolor";

import QRCode from "qrcode.react";

import ErrorMessage from "../error-message/error-message";

import LoadingSpinner from "../loading-indicator/loading-indicator.component";
import updateGuestNameAPI from "../../apis/update-guest";

import FormInput from "../form-input/form-input.component";
import SocialMediaLinks from "../links-footer/links-footer";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import PopupGlobal from "../popup/popup";
import SocialPopupChildComponent from "../popup/child-components/social-media/social-media";

import { checkConditionForSocialPopup } from "../../utils/helpers";

import CheckoutForm from "../card/checkout-form/checkout-form";
import PaymentSuccessCard from "../payment-success/payment-success";
import BeatSpinner from "../loading-indicator/beat-loader";
import { validateEmail } from "../../utils/helpers";

import "./user-info.style.scss";
import "../card/card.style.scss";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import createReducePaymentIntentAPI from "../../apis/create-reduce-payment-intent";

const UserInfo = ({ eventId, promotorId, doormanId, type, uniqueHash }) => {
  const [payLoad, setPayLoad] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPopup, setShowPopUp] = useState(false);
  const [showGlobalPopUp, setShowGlobalPopUp] = useState(true);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [showPaymentView, setShowPaymentView] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await userInfoAPI({
          eventId: eventId,
          promotorId: promotorId,
          uniqueHash: uniqueHash,
        });

        setPayLoad(data.payLoad);
        setIsLoading(false);

        conditionsCheckForQuickInvite(data);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserInfo();
  }, [eventId, promotorId, uniqueHash]);

  const checkConditionForDisplayingPaymentBtn = () => {
    if (
      (payLoad.type?.toLowerCase() === QRTypes.reduce ||
        payLoad.type?.toLowerCase() === QRTypes.FULL) &&
      // payLoad.tickets?.length > 0 &&
      payLoad.transaction?.paymentStatus !== "completed" &&
      payLoad.availableTicket.price !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const createReducePaymentIntentAndSetupStripe = async () => {
    try {
      setIsSubmitting(true);
      const response = await createReducePaymentIntentAPI(
        payLoad.id,
        payLoad.availableTicket.ticketType.name,
        payLoad.availableTicket.id
      );

      if (response.status === "Success") {
        setStripePromise(loadStripe(response.payLoad.publishableKey));
        setClientSecret(response.payLoad.paymentIntent);
        setShowPaymentView(true);
      }
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Error creating PaymentIntent:", error);
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const conditionsCheckForQuickInvite = (data) => {
    const regexCondition = /^mytally[^_]*_.*@noreply\.com$/;
    // console.log(payLoad, "beginning of quick invte check");
    if (data.payLoad.isGuestInfoUpdated === false) {
      setShowPopUp(true);
    }
  };

  const QRdata = JSON.stringify({
    eventId: eventId,
    promotorId: promotorId,
    code: payLoad.code,
    doormanId: doormanId,
    type: type,
    uniqueHash: uniqueHash,
    imageUrl: payLoad.imageUrl,
    invitedBy: payLoad.member?.fullName,
    inviteId: payLoad.id,
  });

  const QRcolor = changeQRColor(
    // payLoad.ticket
    //   ? payLoad.availableTicket.ticketType.name.toLowerCase() :
    !payLoad.transaction && payLoad.type?.toLowerCase(),
    payLoad.member?.email
  );

  if (isLoading) {
    return (
      <div className="profile-card">
        <LoadingSpinner />
      </div>
    );
  }

  if (!type || !doormanId || !uniqueHash || !payLoad.event)
    return (
      <div className="profile-card">
        <ErrorMessage />
      </div>
    );

  if (showPaymentView) {
    return (
      <div className="profile-card">
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm
            payLoad={payLoad}
            clientSecret={clientSecret}
            // selectedTicket={selectedTicket}
            availableTicket={payLoad.availableTicket}
          />
        </Elements>
      </div>
    );
  }

  const CustomPopUp = () => {
    const defaultFormFieldsForPopup = {
      fullNamePopUp: "",
    };

    const [popupNewFormFields, setPopupNewFormFields] = useState(
      defaultFormFieldsForPopup
    );
    const [errorMessagePopup, setErrorMessagePopup] = useState("");
    const [isLoadingPopup, setIsLoadingPopup] = useState(false);

    const onChangeHandlerPopup = (event) => {
      const { name, value } = event.target;
      const updatedFormFields = { ...popupNewFormFields, [name]: value };
      setPopupNewFormFields(updatedFormFields);
    };

    const { fullNamePopUp } = popupNewFormFields;

    // const testConditionForPopupDimesion = () => {
    //   return (
    //     payLoad.isGuestInfoUpdated === false &&
    //     (!payLoad.email || payLoad.email?.match(regexCondition))
    //   );
    // };

    // const regexCondition = /^mytally[^_]*_.*@noreply\.com$/;

    const handleSubmit = async (e) => {
      e.preventDefault();
      // console.log(payLoad, "on beiginng of submitting of popup");

      if (
        payLoad.isGuestInfoUpdated === false &&
        !popupNewFormFields.fullNamePopUp.trim()
      ) {
        alert("Name can not be empty");
        return;
      } else {
        try {
          setIsLoadingPopup(true);

          const updateGuestName = await updateGuestNameAPI(
            eventId,
            payLoad.id,
            uniqueHash,
            fullNamePopUp
          );

          setPayLoad(updateGuestName.payLoad);
          setShowPopUp(false);
        } catch (e) {
          setErrorMessagePopup(e.message);
          console.error(e);
        } finally {
          setIsLoadingPopup(false);
          setTimeout(() => setErrorMessagePopup(""), 3000);
        }
      }
    };

    return (
      <Popup
        open={showPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        overlayStyle={{
          transitionDelay: "1000",
          background: "rgba(0, 0, 0, 0.5)",
        }}
        contentStyle={{
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          background: "rgb(255, 255, 255)",
          width: "320px",
          height: "220px",
          padding: "25px",
        }}
        arrowStyle={{
          color: "rgb(255, 255, 255)",
        }}
      >
        <form className="form-popup" onSubmit={handleSubmit}>
          <h2 className="custom-popup-heading">Please enter your name</h2>
          {payLoad.isGuestInfoUpdated === false && (
            <FormInput
              label="Full Name"
              type="text"
              placeholder="Full Name"
              name="fullNamePopUp"
              value={fullNamePopUp}
              onChange={onChangeHandlerPopup}
              required
            />
          )}
          {/*
          {(!payLoad.email || payLoad.email?.match(regexCondition)) && (
            <FormInput
              label="Email"
              type="text"
              placeholder="Email Address"
              name="emailPopup"
              value={emailPopup}
              onChange={onChangeHandlerPopup}
              required
            />
          )}*/}
          <span className="error-message-container">{errorMessagePopup}</span>
          <button className="sign-in-button" onClick={handleSubmit}>
            {isLoadingPopup ? <BeatSpinner /> : "Submit"}
          </button>
        </form>
      </Popup>
    );
  };

  return (
    // <div className="user-info-card">
    <div className="profile-card">
      <div className="event-information">
        <p className="event-name">{payLoad.event.eventName}</p>
        <p className="event-date">
          {convertTimeWithTimezone(payLoad.event.startTime)}
        </p>
        <p className="event-location">{payLoad.event.location}</p>
      </div>

      <div className="profile-image">
        <img src={payLoad.event.imageUrl} alt="Profile" />
      </div>

      <div className="profile-info">
        {/*payLoad.fullName && (
          <h2 className="user-name">{capitalizeNames(payLoad.fullName)}</h2>
        )*/}
        {/*<h2 className="invited-by">Invited by</h2>
        <p className="invited-by-name">
          {payLoad.member.userType === USER_ROLES.ADMIN
            ? "Event Owner"
            : capitalizeNames(payLoad.member.fullName)}
          </p>*/}
        {payLoad.fullName && (
          <p className="user-name">
            <strong>Guest name: </strong>
            {capitalizeNames(payLoad.fullName)}
          </p>
        )}
        <p className="user-name">
          <strong>Invited by: </strong>
          {payLoad.member.userType === USER_ROLES.ADMIN
            ? "Event Owner"
            : capitalizeNames(payLoad.member.fullName)}
        </p>
        {
          //   payLoad.transaction && (
          //   <h3 className="selected-ticket-name">Paid Ticket</h3>
          // )
        }
        <h3 className="selected-ticket-name">
          {payLoad.transaction
            ? "Paid Ticket"
            : payLoad.type.toLowerCase() === QRTypes.comps
            ? "Complimentary Ticket"
            : payLoad.type.toLowerCase() === QRTypes.FULL
            ? "General Admission Ticket"
            : payLoad.availableTicket.ticketType.name + " Ticket"}
        </h3>
      </div>

      {payLoad.type?.toLowerCase() === QRTypes.comps &&
        !payLoad.transaction && (
          <p className="QR-optional-text">
            Present your QR code
            <br />
            at the door to claim your ticket
          </p>
        )}

      {payLoad.type?.toLowerCase() === QRTypes.reduce &&
        !payLoad.transaction && (
          <p className="QR-optional-text">
            Present your QR code
            <br />
            at the door to receive your reduced ticket
          </p>
        )}

      {payLoad.type?.toLowerCase() === QRTypes.FULL && !payLoad.transaction && (
        <p className="QR-optional-text">Present your QR code at the door</p>
      )}

      {payLoad.transaction && (
        <p className="QR-optional-text">
          Present your QR code
          <br />
          at the door to claim your ticket
        </p>
      )}

      <div className="QR-content">
        {/*payLoad.type === QRTypes.REDUCE || payLoad.type === QRTypes.reduce ? (
        //   <div className="QR-reduce-type-text-container">
        //     <p className="QR-reduce-type-text"> Reduce Ticket</p>
        //   </div>
        // ) : (
        //   ""
        // )}

        // {payLoad.type === QRTypes.COMPS || payLoad.type === QRTypes.comps ? (
        //   <div className="QR-reduce-type-text-container">
        //     <p className="QR-comps-type-text"> Comp Ticket</p>
        //   </div>
        // ) : (
        //   ""
        // )}

        {/*<p className="message-text">*/}
        {/*  {payLoad.isVerified*/}
        {/*    ? "Your check-in was successfully verified"*/}
        {/*    : "Present this barcode at the door for expedited entry"}*/}
        {/*</p>*/}

        <div className="QR-code-container">
          <QRCode value={QRdata} fgColor={QRcolor} />

          <p className="code"> code: {payLoad.code}</p>
        </div>
      </div>

      {payLoad.type.toLowerCase() === QRTypes.reduce &&
        !payLoad.transaction && (
          <div style={{ marginTop: "10px" }}>
            <span className="QR-optional-text">
              Reduced Ticket is valid subject to venue capacity
            </span>
          </div>
        )}

      {checkConditionForDisplayingPaymentBtn() && (
        <Fragment>
          {
            // <div className="separator"></div>
            // <h2 className="ticket-type-heading">Choose Ticket</h2>
            // <div className="view-toggle">
            //   {payLoad.tickets.map((ticket) => (
            //     <button
            //       key={ticket.id}
            //       className={`toggle-button ${
            //         selectedTicket.id === ticket.id
            //           ? `active-${
            //               selectedTicket.ticketType.name.toLowerCase() ===
            //               "reduce"
            //                 ? "reduce"
            //                 : "full"
            //             }`
            //           : ""
            //       }`}
            //       onClick={() => {
            //         setSelectedTicket(ticket);
            //       }}
            //     >
            //       <div>{ticket.ticketType.name}</div>
            //       <div className="price">${ticket.price}</div>
            //     </button>
            //   ))}
            // </div>
          }

          <button
            className="payment-button"
            disabled={isLoading}
            onClick={createReducePaymentIntentAndSetupStripe}
          >
            {isSubmitting ? (
              <BeatSpinner />
            ) : payLoad.type.toLowerCase() === QRTypes.reduce ? (
              "Secure Your Ticket Now!"
            ) : (
              "Save Time - Buy Ticket Now!"
            )}
          </button>
        </Fragment>
      )}
      {errorMessage && (
        <span className="error-message-payment">{errorMessage}</span>
      )}

      {/*<div className="text-container">*/}
      {/*  <p className="venue-text">*/}
      {/*    <strong>Venue:</strong> {payLoad.event.location}*/}
      {/*  </p>*/}
      {/*</div>*/}

      {showPopup && <CustomPopUp />}
      {showGlobalPopUp &&
        !showPopup &&
        checkConditionForSocialPopup(payLoad) && (
          <PopupGlobal
            setShowGlobalPopup={setShowGlobalPopUp}
            showGlobalPopup={showGlobalPopUp}
            ChildComponent={() => (
              <SocialPopupChildComponent
                instagramLink={payLoad.event.socialMedia.instagramUrl}
                facebookLink={payLoad.event.socialMedia.facebookUrl}
                youtubeLink={payLoad.event.socialMedia.youtubeUrl}
                tiktokLink={payLoad.event.socialMedia.tiktokUrl}
                whatsAppLink={payLoad.event.socialMedia.whatsAppUrl}
              />
            )}
            popupHeading="Follow us on"
          />
        )}
    </div>
    //   <SocialMediaLinks
    //     instagramLink={payLoad.event.socialMedia.instagramUrl}
    //     facebookLink={payLoad.event.socialMedia.facebookUrl}
    //     youtubeLink={payLoad.event.socialMedia.youtubeUrl}
    //     tiktokLink={payLoad.event.socialMedia.tiktokUrl}
    //   />
    // </div>
  );
};

export default UserInfo;

// <h2 className="user-name">{payLoad.member.fullName}</h2>;
